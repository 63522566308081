import Vue from "vue";
import Vuex from "vuex";
import options from "./modules/options";
import filters from "./modules/filters";
import vehicle from "./modules/vehicle";
import favorites from "./modules/favorites";
import dealers from "./modules/dealers";
import calculations from "./modules/calculations";
import Axios from "axios";
import config from "../plugins/config";
import createPersistedState from "vuex-persistedstate";

const storage = createPersistedState({
  key: "toyota-vaihtoautohaku",
  paths: ["favorites"]
});

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    results: [],
    currentPage: 1,
    perPage: 15,
    total: 0,
    lastPage: null,
    resultsLoading: false,
    isVideo: false
  },
  getters: {
    isVideo: state => state.isVideo
  },
  mutations: {
    SET_RESULTS: (state, payload) => {
      state.results = payload.data;
      state.total = payload.total;
      state.perPage = payload.per_page;
      state.currentPage = payload.current_page;
      state.lastPage = payload.last_page;
    },
    SET_LOADING: (state, payload) => {
      state.resultsLoading = payload;
    },
    SET_VIDEO: (state, payload) => {
      state.isVideo = payload;
    }
  },
  actions: {
    fetchResults({ commit, rootState }) {
      commit("SET_LOADING", true);
      let filters = rootState.filters;
      return Axios.get(config.baseUrl + "vehicle", { params: filters })
        .then(result => {
          commit("SET_RESULTS", result.data);
          commit("SET_LOADING", false);
        })
        .catch(err => {
          throw err;
        });
    },
    setVideo({ commit }, payload) {
      commit("SET_VIDEO", payload);
    },
    toPage({ commit, rootState }, payload) {
      commit("SET_LOADING", true);
      let filters = rootState.filters;
      return Axios.get(config.baseUrl + "vehicle", {
        params: { ...filters, page: payload }
      })
        .then(result => {
          commit("SET_RESULTS", result.data);
          commit("SET_LOADING", false);
        })
        .catch(err => {
          throw err;
        });
    }
  },
  modules: {
    options,
    filters,
    vehicle,
    favorites,
    dealers,
    calculations
  },
  plugins: [storage]
});
