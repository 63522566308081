import axios from "axios";
import config from "../../plugins/config";

const state = {
  locations: [],
  makes: {},
  power: {},
  volume: {},
  modelYear: {}
};
const getters = {
  getLocations: state => state.locations,
  getMakes: state => state.makes,
  getModels: state => make => state.makes[make],
  getPower: state => state.power,
  getVolume: state => state.volume,
  getModelYear: state => state.modelYear
};
const actions = {
  fetchOptions({ commit }) {
    return axios
      .get(config.baseUrl + "options")
      .then(result => {
        commit("SET_OPTIONS", result.data);
      })
      .catch(err => {
        throw err;
      });
  }
};
const mutations = {
  SET_OPTIONS: (state, payload) => {
    state.locations = payload.locations;
    state.makes = payload.makes;
    state.power = payload.power;
    state.volume = payload.volume;
    state.modelYear = payload.modelYear;
  }
};
export default { namespaced: true, state, getters, actions, mutations };
