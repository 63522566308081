import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import Vue2TouchEvents from "vue2-touch-events";
import Vuelidate from "vuelidate";
import Axios from "axios";
import VueMeta from "vue-meta";
import VTooltip from "v-tooltip";
Vue.use(VTooltip);
Vue.use(VueMeta);
Vue.use(Vuelidate);

Vue.use(Vue2TouchEvents);

Vue.config.productionTip = false;

Vue.filter("price", val => {
  if (!val) return;
  return (
    parseFloat(val)
      .toFixed(2)
      .replace(".", ",") + " €"
  );
});
Vue.filter("mileage", val => {
  if (!val) return;
  return parseInt(val).toLocaleString("fi-FI") + " km";
});

Vue.filter("fuelType", val => {
  if (!val) return;
  let type = "";
  switch (val) {
    case "B":
      type = "Bensiini";
      break;

    case "H":
      type = "Hybridi";
      break;

    case "D":
      type = "Diesel";
      break;

    case "S":
      type = "Sähkö";
      break;

    case "K":
      type = "Kaasu";
      break;

    case "E":
      type = "E85";
      break;

    default:
      type = val;
      break;
  }

  return type;
});

Vue.filter("bodyType", val => {
  if (!val) return;
  let type = "";
  switch (val) {
    case "1":
      type = "Sedan";
      break;

    case "3":
      type = "Hatchback";
      break;

    case "4":
      type = "Coupé";
      break;

    case "5":
      type = "Farmari";
      break;

    case "6":
      type = "Cabriolet";
      break;

    case "7":
      type = "Minivan";
      break;

    case "8":
      type = "Maastoauto";
      break;

    case "A":
      type = "Matkailuauto";
      break;

    case "PU":
      type = "Pickup";
      break;

    default:
      type = val;
      break;
  }
  return type;
});

Vue.filter("transmissionType", val => {
  if (!val) return;
  let type = "";
  switch (val) {
    case "M":
      type = "Manuaali";
      break;

    default:
      type = "Automaatti";
      break;
  }
  return type;
});

Vue.filter("driveType", val => {
  if (!val) return;
  let type = "";
  switch (val) {
    case "FWD":
      type = "Etuveto";
      break;

    case "RWD":
      type = "Takaveto";
      break;

    case "AWD":
      type = "Neliveto";
      break;

    default:
      type = "Ei tiedossa";
      break;
  }
  return type;
});

Vue.filter("vehicleType", val => {
  if (!val) return;
  let type = "";
  switch (val) {
    case "0":
      type = "Peräkärry";
      break;
    case "1":
      type = "Bussi";
      break;
    case "2":
      type = "Kuorma-auto";
      break;
    case "3":
      type = "Erikoisauto";
      break;
    case "4":
      type = "Pakettiauto";
      break;
    case "5":
      type = "Henkilöauto";
      break;
    case "6":
      type = "Traktori";
      break;
    case "7":
      type = "Työkone";
      break;
    case "8":
      type = "Maastoajoneuvo";
      break;
    case "9":
      type = "Moottoripyörä";
      break;
    case "A":
      type = "Kolmipyörä";
      break;
    case "B":
      type = "Mopo";
      break;
    case "MZ":
      type = "Muu";
      break;
    case "Z1":
      type = "Trukki";
      break;
    default:
      type = "Ei tiedossa";
      break;
  }
  return type;
});

Vue.component("modal", () =>
  import(/* webpackChunkName: 'modal' */ "./components/Modal.vue")
);
import config from "./plugins/config";
let baseUrl = config.baseUrl;

Axios.interceptors.request.use(config => {
  if (config.url.startsWith(baseUrl)) {
    config.headers["X-source"] = window.location.hostname;
  }
  return config;
});

router.afterEach(() => {
  if (window.ga) {
    console.log("ga found: sending pageview");
    window.ga("send", {
      hitType: "pageview",
      page: window.location.href
    });
    console.log("pageview sent: " + window.location.href);
  }
  console.log(window.location);
});

import eventBus from "./plugins/bus";

Vue.prototype.$bus = eventBus;

new Vue({
  router,
  store,
  render: h => h(App),
  mounted() {
    store.dispatch("options/fetchOptions");
    store.dispatch("dealers/fetchDealers");
    store.dispatch("calculations/fetchPrecondition");
    
  }
}).$mount("#app");
