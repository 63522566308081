const state = {
  favorites: []
};
const getters = {
  isFavorite: state => licensePlateNumber => {
    return (
      state.favorites.findIndex(
        favorite => favorite.licensePlateNumber === licensePlateNumber
      ) !== -1
    );
  },
  countFavorites: state => state.favorites.length,
  getFavorites: state => state.favorites
};
const actions = {};
const mutations = {
  ADD_FAVORITE: (state, payload) => {
    state.favorites.push(payload);
  },
  REMOVE_FAVORITE: (state, payload) => {
    let i = state.favorites.findIndex(
      favorite => favorite.licensePlateNumber === payload
    );
    if (i === -1) {
      return;
    }

    state.favorites.splice(i, 1);
  }
};
export default { namespaced: true, state, getters, actions, mutations };
