import axios from "axios";

const state = {
  dealers: []
};
const getters = {
  getByNMSC: state => id =>
    state.dealers.find(d => {
      if ("NMSC" in d.applications) {
        return parseInt(d.applications["NMSC"].externalID) === parseInt(id);
      } else {
        return false;
      }
    })
};
const actions = {
  fetchDealers({ commit }) {
    axios
      .get("https://a2d-proxy.lib.toyota.fi/api/data/by_name", {
        headers: {
          Authorization:
            "Bearer wHwS1IhkJ9kkg5mpsyai12LqLi4TPYd1kDHL3SIzz5PGnMuqRMxiRtnoaM1J"
        }
      })
      .then(result => {
        commit("SET_DEALERS", result.data);
      })
      .catch(err => {
        console.error(err);
      });
  }
};
const mutations = {
  SET_DEALERS: (state, payload) => {
    state.dealers = payload;
  }
};
export default { namespaced: true, state, getters, actions, mutations };
