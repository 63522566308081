// let baseUrl = "https://toyota-vaihtoautot.docker.localhost/api/";
// let baseUrl = "https://tvapi.staging.letm.com/api/";
let mapBoxToken = process.env.VUE_APP_MAPBOX;
// if (process.env.NODE_ENV === "production") {
//   baseUrl = "https://tvapi.staging.letm.com/api/";
// }
// if (process.env.NODE_ENV === "staging") {
//   baseUrl = "https://tvapi.staging.letm.com/api/";
// }
let baseUrl = process.env.VUE_APP_BASEURL;
console.log(process.env.NODE_ENV);

const config = {
  baseUrl: baseUrl,
  mapBoxToken: mapBoxToken
};

export default config;
