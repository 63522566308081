const makeFilter = window.isJM ? "" : "TOYOTA";

const state = {
  model: "",
  make: makeFilter,
  max_km: "",
  max_price: "",
  location: "",
  year_min: "",
  year_max: "",
  fuelType: "",
  transmissionType: "",
  bodyType: "",
  volume_min: "",
  volume_max: "",
  power_min: "",
  power_max: "",
  driveType: "",
  licensePlateNumber: "",
  sortBy: "year",
  sortDir: "DESC"
};
const getters = {};
const actions = {};
const mutations = {
  SET_FIELD: (state, payload) => {
    state[payload.field] = payload.value;
  }
};
export default { namespaced: true, state, getters, actions, mutations };
