<template>
  <div style="display: inline-block;">
    <transition name="modal">
      <div class="modal-mask" v-if="modal">
        <div
          class="modal-wrapper"
          @click.stop="closeOnClick ? toggle() : false"
        >
          <div
            class="v-modal-container error-modal"
            @click.stop
            :style="{ width: width }"
          >
            <div class="modal-inner">
              <div
                class="modal-header pb d-flex align-items-center justify-content-between"
              >
                <slot name="header" :on="toggle"></slot>
              </div>
              <div class="modal-body pb-2">
                <slot :on="toggle"></slot>
              </div>
              <div class="modal-footer">
                <slot name="footer"></slot>
              </div>
            </div>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  props: {
    closeOnClick: {
      type: Boolean
    },
    width: {
      type: String,
      default: "100%"
    },
    modal: {
      type: Boolean,
      default: () => false
    }
  },
  data: () => ({}),
  methods: {
    toggle() {
      this.$emit("close", true);
    }
  },
  watch: {
    modal(val) {
      if (val) {
        document.body.style.overflow = "hidden";
      } else {
        document.body.style.overflow = "auto";
      }
    }
  }
};
</script>

<style lang="scss" scoped>
/*
 * The following styles are auto-applied to elements with
 * transition="modal" when their visibility is toggled
 * by Vue.js.
 *
 * You can easily play with the modal transition by editing
 * these styles.
 */

.modal-enter {
  opacity: 0;
}

.modal-leave-active {
  opacity: 0;
}

.modal-enter .modal-container,
.modal-leave-active .modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}

.error-modal {
  .modal-inner {
    color: #fff;
    background-color: #e74c3c;
  }
}
</style>
