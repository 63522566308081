<template>
  <div
    class="home py-3 py-md-5 d-flex align-items-center justify-content-center flex-column"
  >
    <div class="container-xl">
      <h1 class="pb-3 text-md-center">Hae vaihtoautoja</h1>
    </div>
    <search-options></search-options>
  </div>
</template>

<script>
// @ is an alias to /src
import SearchOptions from "@/components/Search/SearchOptions";
export default {
  name: "Home",
  components: {
    SearchOptions
  }
};
</script>
<style lang="scss">
.t-vaihtoautohaku .home {
  background: linear-gradient(180deg, #ededed 0%, #ffffff 100%);
  @media (min-width: 768px) {
    min-height: 75vh;
  }
  h1 {
    text-transform: uppercase;
    font-weight: normal;
  }
  .options {
    padding-top: 0;
    padding-bottom: 0;
    background: transparent;
  }
}
</style>
