<template>
  <div id="app" class="t-vaihtoautohaku">
    <router-view />
    <template v-if="errors.length">
      <ErrorVue
        v-for="(error, idx) in errors"
        :key="idx"
        :modal="idx + 1 == errors.length"
        @close="closeError()"
      >
        <template #header="{on}">
          <div></div>
          <div>
            <a @click="on"
              ><button class="btn btn-inline">
                Sulje <span class="icon-remove"></span></button
            ></a>
          </div>
        </template>
        <template>
          <p v-for="(row, id) in error" :key="id">
            {{ row }}
          </p>
        </template>
      </ErrorVue>
    </template>
  </div>
</template>
<script>
import ErrorVue from "./components/Error.vue";
export default {
  data: () => ({
    errors: []
  }),
  components: {
    ErrorVue
  },
  mounted() {
    let app = document.getElementsByClassName("t-vaihtoautohaku")[0];
    app.parentElement.style.paddingLeft = 0;
    app.parentElement.style.paddingRight = 0;
    this.$bus.$on("error", error => {
      this.errors.push(error.errors);
    });
  },
  methods: {
    closeError() {
      this.errors.pop();
    }
  },
  metaInfo() {
    return {
      title: "Toyota vaihtoautohaku"
    };
  }
};
</script>
<style lang="scss">
// @import "https://lib.toyota.fi/t1-lmss/latest/dist/css/style.min.css";
@import "./fonts/font-awesome/scss/font-awesome.scss";
.t-vaihtoautohaku {
  font: 16px/24px ToyotaType-Book, Arial, Helvetica, sans-serif;
  // @import "./styles/t1-lmss-master/dist/css/style.min";

  #nav {
    position: sticky;
    top: 0;
    background-color: #fff;

    a {
      font-weight: bold;
      color: #2c3e50;

      // &.router-link-exact-active {
      //   color: #42b983;
      // }
    }
  }
}
@import "./styles/main.scss";
body {
  margin: 0;
  padding: 0;
}
.fas {
  font-family: FontAwesome;
  font-style: normal;
}

#app {
  // .row {
  //   display: flex;
  //   .col {
  //     flex: 1;
  //   }
  // }

  // .flex-column {
  //   display: flex;
  //   flex-direction: column;
  // }
}
</style>
