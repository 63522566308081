<template>
  <div class="options container-fluid">
    <div class="search-options text-md-center">
      <div class="d-flex flex-wrap justify-content-sm-center">
        <div
          class="col-12 col-md-auto px-0 d-md-inline-flex align-items-center justify-content-center"
        >
          Etsin
          <select id="merkki" v-model="make" class="form-control">
            <label for="merkki" class="sr-only">Valitse merkki</label>
            <option value="" selected>Merkki</option>
            <option value="TOYOTA" style="font-size: 16px;">TOYOTA</option>
            <option value="LEXUS" style="font-size: 16px;">LEXUS</option>
            <option
              style="font-size: 16px;"
              v-for="(make, key) in makes"
              :value="key"
              :key="key"
              >{{ key }}</option
            >
          </select>

          <select id="malli" v-model="model" class="form-control">
            <label for="malli" class="sr-only">Valitse malli</label>
            <option value="" selected>Malli</option>
            <option
              style="font-size: 16px;"
              v-for="model in models"
              :value="model"
              :key="model"
              >{{ model }}</option
            >
          </select>
        </div>
        <div
          class="col-12 col-md-auto px-0 d-md-inline-flex align-items-center justify-content-center"
        >
          vaihtoautoa paikkakunnalta
          <select id="paikkakunta" v-model="location" class="form-control">
            <label for="paikkakunta" class="sr-only">Valitse paikkakunta</label>
            <option value="" selected>Paikkakunta</option>
            <option
              style="font-size: 16px;"
              v-for="location in locations"
              :value="location"
              :key="location"
              >{{ location }}</option
            >
          </select>
        </div>
        <div class="w-100 d-block"></div>
        <div
          class="col-12 col-md-auto px-0 d-md-inline-flex align-items-center justify-content-center mr-md-1"
        >
          jonka maksimihinta on
          <div>
            <label for="maksimihinta" class="sr-only"
              >Valitse maksimihinta</label
            >
            <input
              id="maksimihinta"
              type="number"
              class="form-control d-inline"
              v-model="max_price"
              placeholder="€"
            />euroa
          </div>
        </div>
        <div
          class="col-12 col-md-auto px-0 d-md-inline-flex align-items-center justify-content-center"
        >
          ja ajettu enintään
          <label for="kilometrit" class="sr-only"
            >Valitse maksimikilometrit</label
          >
          <input
            id="kilometrit"
            type="number"
            class="form-control"
            placeholder="km"
            v-model="max_km"
          />
          kilometriä
        </div>
      </div>
      <div class="py-2 text-center">
        <button class="btn btn-function" @click="showExtra = !showExtra">
          Tarkenna hakua
        </button>
        <button class="btn btn-primary" @click="search()">Hae</button>
      </div>
    </div>
    <div v-if="false != false">
      <modal close-on-click>
        <template v-slot:activator="{ on }">
          <button class="btn btn-text" @click="on">Luo hakuvahti</button>
        </template>
        <template #header>
          <h2>Luo hakuvahti</h2>
        </template>
      </modal>
      <button class="btn btn-text" @click="$router.push({ name: 'Favorites' })">
        Suosikit
      </button>
    </div>
    <transition name="slide">
      <extra-options v-if="showExtra"></extra-options>
    </transition>
    <datalist id="locations">
      <option v-for="location in locations" :value="location" :key="location" />
    </datalist>
  </div>
</template>

<script>
import { mapGetters, mapState, mapActions } from "vuex";
import ExtraOptions from "./ExtraOptions";
export default {
  data: () => ({
    showExtra: false
  }),
  computed: {
    ...mapState({
      filters: "filters"
    }),
    ...mapGetters({
      locations: "options/getLocations",
      makes: "options/getMakes",
      getModels: "options/getModels"
    }),
    models() {
      return this.make.length ? this.getModels(this.make) : [];
    },
    make: {
      get() {
        return this.filters.make;
      },
      set(val) {
        this.setField("make", val);
      }
    },
    model: {
      get() {
        return this.filters.model;
      },
      set(val) {
        this.setField("model", val);
      }
    },
    max_km: {
      get() {
        return this.filters.max_km;
      },
      set(val) {
        this.setField("max_km", val);
      }
    },
    max_price: {
      get() {
        return this.filters.max_price;
      },
      set(val) {
        this.setField("max_price", val);
      }
    },
    location: {
      get() {
        return this.filters.location;
      },
      set(val) {
        this.setField("location", val);
      }
    }
  },
  methods: {
    ...mapActions({
      fetchResults: "fetchResults"
    }),
    search() {
      this.fetchResults()
        .then(() => {
          if (this.$route.name !== "Results") {
            this.$router.push({ name: "Results" });
          }
        })
        .catch(err => {
          console.error(err);
        });
    },
    setField(field, value) {
      this.$store.commit("filters/SET_FIELD", { field, value });
    }
  },
  watch: {
    make(val, old) {
      if (val !== old) {
        this.model = "";
      }
    }
  },
  components: {
    ExtraOptions
  }
};
</script>

<style lang="scss" scoped>
.t-vaihtoautohaku {
  .btn + .btn {
    margin: 0 8px 8px;
  }
  .btn {
    margin-bottom: 8px;
  }
  .options {
    background: linear-gradient(180deg, #ededed 0%, #ffffff 100%);
    padding-top: 4rem;
    padding-bottom: 4rem;
    border-bottom: 0px solid transparent !important;
    text-align: left;
    /* Chrome, Safari, Edge, Opera */
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
    @media (min-width: 960px) {
      padding-top: 8rem;
      padding-bottom: 8rem;
    }
    select,
    input {
      width: auto;
      margin-top: 8px;
      margin-bottom: 8px;
      min-width: 150px;
      color: rgb(106, 107, 112);
      background: #fff;
      border: 1px solid #d9d9d9;
      border-radius: 6px;
      @media (min-width: 768px) {
        max-width: 220px; // voiko laittaa?
        display: inline-block;
        margin-right: 8px;
        margin-left: 8px;
        font-size: 22px;
        line-height: 28px;
      }
    }
    .form-control {
      display: block;
      width: 100%;
      height: auto !important;
      @media (min-width: 768px) {
        display: inline;
        width: auto;
        max-width: 220px;
      }
    }

    /* Firefox */
    input[type="number"] {
      -moz-appearance: textfield;
    }
  }

  .slide-enter-active {
    -moz-transition-duration: 0.3s;
    -webkit-transition-duration: 0.3s;
    -o-transition-duration: 0.3s;
    transition-duration: 0.3s;
    -moz-transition-timing-function: ease-in-out;
    -webkit-transition-timing-function: ease-in-out;
    -o-transition-timing-function: ease-in-out;
    transition-timing-function: ease-in-out;
  }

  .slide-leave-active {
    -moz-transition-duration: 0.3s;
    -webkit-transition-duration: 0.3s;
    -o-transition-duration: 0.3s;
    transition-duration: 0.3s;
    -moz-transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
    -webkit-transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
    -o-transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
    transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
  }

  .slide-enter-to,
  .slide-leave {
    max-height: 280px;
    overflow: hidden;
  }

  .slide-enter,
  .slide-leave-to {
    overflow: hidden;
    max-height: 0;
  }
}
</style>
