<template>
  <div class="extra-options container py-2">
    <div class="row pb-2">
      <div class="col-12 col-lg">
        <div class="pb-2">
          <label>
            Käyttövoima
            <i
              v-if="fuelType"
              class="icon-remove"
              @click="fuelType = null"
              style="color: #f02;"
            ></i>
          </label>
          <input
            class="form-check-input"
            id="bensiini"
            type="radio"
            value="B"
            name="fuelType"
            v-model="fuelType"
          />
          <label class="form-check-inline mr-1" for="bensiini">
            Bensiini
          </label>
          <input
            class="form-check-input"
            id="diesel"
            type="radio"
            value="D"
            name="fuelType"
            v-model="fuelType"
          />
          <label class="form-check-inline mr-1" for="diesel">
            Diesel
          </label>
          <input
            class="form-check-input"
            id="hybridi"
            type="radio"
            value="H"
            name="fuelType"
            v-model="fuelType"
          />
          <label class="form-check-inline mr-1" for="hybridi">
            Hybridi
          </label>
        </div>
        <div class="pb-2">
          <label>
            Vaihteisto
            <i
              v-if="transmissionType"
              class="icon-remove"
              @click="transmissionType = null"
              style="color: #f02;"
            ></i>
          </label>
          <input
            class="form-check-input"
            type="radio"
            value="A"
            name="transmissionType"
            v-model="transmissionType"
            id="automaatti"
          />
          <label class="form-check-inline mr-1" for="automaatti">
            Automaatti
          </label>
          <input
            class="form-check-input"
            type="radio"
            value="M"
            name="transmissionType"
            v-model="transmissionType"
            id="manuaali"
          />
          <label class="form-check-inline mr-1" for="manuaali">
            Manuaali
          </label>
        </div>
        <div class="pb-2">
          <label>
            Vetotapa
            <i
              v-if="driveType"
              class="icon-remove"
              @click="driveType = null"
              style="color: #f02;"
            ></i>
          </label>
          <input
            class="form-check-input"
            type="radio"
            name="driveType"
            value="FWD"
            v-model="driveType"
            id="fwd"
          />
          <label class="form-check-inline mr-1" for="fwd">
            Etuveto
          </label>
          <input
            class="form-check-input"
            type="radio"
            name="driveType"
            value="RWD"
            v-model="driveType"
            id="rwd"
          />
          <label class="form-check-inline mr-1" for="rwd">
            Takaveto
          </label>
          <input
            class="form-check-input"
            type="radio"
            name="driveType"
            value="AWD"
            v-model="driveType"
            id="awd"
          />
          <label class="form-check-inline mr-1" for="awd">
            Neliveto
          </label>
        </div>
      </div>
      <div class="col-12 col-lg">
        <div class="pb-2">
          <label>Vuosimalli</label>
          <select v-model="year_min">
            <option value="" selected>Ei alarajaa</option>
            <option v-for="year in yearOptions" :key="year" :value="year">{{
              year
            }}</option>
          </select>
          <select v-model="year_max">
            <option value="" selected>Ei ylärajaa</option>
            <option
              v-for="year in yearOptions"
              :key="year"
              :value="year"
              :disabled="year < year_min"
              >{{ year }}</option
            >
          </select>
        </div>
        <div class="pb-2">
          <label>Korimalli</label>
          <select v-model="bodyType">
            <option value="" selected>Valitse</option>
            <option
              v-for="(bodyType, idx) in bodyTypes"
              :key="idx"
              :value="idx"
              >{{ bodyType }}</option
            >
          </select>
        </div>
        <div class="pb-2">
          <label>Moottoritilavuus</label>
          <select v-model="volume_min">
            <option value="" selected>Ei alarajaa</option>
            <option
              v-for="volume in volumeOptions"
              :key="volume"
              :value="volume"
              >{{ volume }}</option
            >
          </select>
          <select v-model="volume_max">
            <option value="" selected>Ei ylärajaa</option>
            <option
              v-for="volume in volumeOptions"
              :key="volume"
              :value="volume"
              >{{ volume }}</option
            >
          </select>
        </div>
      </div>
      <div class="col-6 col-lg">
        <div class="pb-2">
          <label>Teho (kW)</label>
          <select v-model="power_min">
            <option value="" selected>Ei alarajaa</option>
            <option v-for="power in powerOptions" :key="power" :value="power">{{
              power
            }}</option>
          </select>
          <select v-model="power_max">
            <option value="" selected>Ei ylärajaa</option>
            <option v-for="power in powerOptions" :key="power" :value="power">{{
              power
            }}</option>
          </select>
        </div>
        <div class="pb-2">
          <label>Rekisterinumero</label>
          <input
            type="text"
            class="form-control"
            v-model="licensePlateNumber"
          />
        </div>
        <div class="checkbox">
          <input id="naytaApproved" type="checkbox" v-model="onlyApproved" />
          <label for="naytaApproved"
            >Näytä vain Toyota Approved Vaihtoautot</label
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import _ from "lodash";
export default {
  computed: {
    ...mapState({
      filters: "filters",
      options: "options"
    }),
    bodyTypes() {
      return {
        "1": "Sedan",
        "3": "Hatchback",
        "4": "Coupé",
        "5": "Farmari",
        "6": "Cabriolet",
        "7": "Minivan",
        "8": "Maastoauto",
        A: "Asuntoauto",
        PU: "Pickup"
      };
    },
    year_min: {
      get() {
        return this.filters.year_min;
      },
      set(val) {
        this.setField("year_min", val);
      }
    },
    year_max: {
      get() {
        return this.filters.year_max;
      },
      set(val) {
        this.setField("year_max", val);
      }
    },
    fuelType: {
      get() {
        return this.filters.fuelType;
      },
      set(val) {
        this.setField("fuelType", val);
      }
    },
    transmissionType: {
      get() {
        return this.filters.transmissionType;
      },
      set(val) {
        this.setField("transmissionType", val);
      }
    },
    bodyType: {
      get() {
        return this.filters.bodyType;
      },
      set(val) {
        this.setField("bodyType", val);
      }
    },
    volume_min: {
      get() {
        return this.filters.volume_min;
      },
      set(val) {
        this.setField("volume_min", val);
      }
    },
    volume_max: {
      get() {
        return this.filters.volume_max;
      },
      set(val) {
        this.setField("volume_max", val);
      }
    },
    power_min: {
      get() {
        return this.filters.power_min;
      },
      set(val) {
        this.setField("power_min", val);
      }
    },
    power_max: {
      get() {
        return this.filters.power_max;
      },
      set(val) {
        this.setField("power_max", val);
      }
    },
    driveType: {
      get() {
        return this.filters.driveType;
      },
      set(val) {
        this.setField("driveType", val);
      }
    },
    licensePlateNumber: {
      get() {
        return this.filters.licensePlateNumber;
      },
      set(val) {
        this.setField("licensePlateNumber", val);
      }
    },
    onlyApproved: {
      get() {
        let isToyota = this.filters.make === "TOYOTA";
        let maxMileage = parseInt(this.filters.max_km) === 160000;
        let maxYears = this.year_min === new Date().getFullYear() - 8;
        return isToyota && maxMileage && maxYears;
      },
      set(val) {
        if (val) {
          if (this.filters.make !== "TOYOTA") {
            this.setField("model", "");
            this.setField("make", "TOYOTA");
          }
          this.setField("max_km", 185000);
          this.setField("year_min", new Date().getFullYear() - 10);
        }
      }
    },
    powerOptions() {
      if (!("max" in this.options.power)) {
        return [];
      }
      return _.range(this.options.power.min, this.options.power.max, 10);
    },
    volumeOptions() {
      if (!("max" in this.options.volume)) {
        return [];
      }
      return _.range(this.options.volume.min, this.options.volume.max, 100);
    },
    yearOptions() {
      return _.rangeRight(
        this.options.modelYear.min,
        this.options.modelYear.max + 1,
        1
      );
    },
    nextYear() {
      return new Date().getYear() + 1;
    }
  },
  methods: {
    setField(field, value) {
      this.$store.commit("filters/SET_FIELD", { field, value });
    }
  }
};
</script>

<style lang="scss">
.extra-options {
  select {
    display: inline-block;
    margin-right: 4px;
    min-width: calc(50% - 8px);
    //border-color: #f0f0f0;
    width: auto;
    font-size: 14px;
    /*background-color: #fff;
    background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='utf-8'%3F%3E%3Csvg width='1792' height='1792' viewBox='0 0 1792 1792' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1683 808l-742 741q-19 19-45 19t-45-19l-742-741q-19-19-19-45.5t19-45.5l166-165q19-19 45-19t45 19l531 531 531-531q19-19 45-19t45 19l166 165q19 19 19 45.5t-19 45.5z' fill='%23e50000'/%3E%3C/svg%3E"),
      linear-gradient(to bottom, #fff 0, #fff 100%);
    &:hover {
      background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='utf-8'%3F%3E%3Csvg width='1792' height='1792' viewBox='0 0 1792 1792' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1683 808l-742 741q-19 19-45 19t-45-19l-742-741q-19-19-19-45.5t19-45.5l166-165q19-19 45-19t45 19l531 531 531-531q19-19 45-19t45 19l166 165q19 19 19 45.5t-19 45.5z' fill='%23e50000'/%3E%3C/svg%3E"),
        linear-gradient(to bottom, #f0f0f0 0, #f0f0f0 100%);
    }*/
  }
  /*
  .form-control {
    background-color: #fff;
    &:hover {
      background-color: #f0f0f0;
    }
  }*/
  .checkbox label {
    font-size: 14px;
  }
}
</style>
