import Vue from "vue";
import Axios from "axios";
import config from "../../plugins/config";
import bus from "../../plugins/bus";

const state = {
  calculations: {},
  loadingCalculation: false,
  precondition: {}
};
const getters = {
  hasCalculation: state => licensePlateNumber =>
    licensePlateNumber in state.calculations,
  getCalculation: state => licensePlateNumber =>
    state.calculations[licensePlateNumber],
  getPrecondition: state => state.precondition,
  getLoading: state => state.loadingCalculation
};
const actions = {
  calculate({ commit }, payload) {
    console.log(payload);
    commit("SET_LOADING", true);
    let c = payload.isFlex ? "flex" : "part";
    let url = config.baseUrl + "calculation/" + c;
    console.log(url);
    return Axios.post(url, payload)
      .then(result => {
        console.log(result.data);
        commit("SET_CALCULATION", result.data);
        commit("SET_LOADING", false);
      })
      .catch(err => {
        console.error(err);
        commit("SET_LOADING", false);
        bus.$emit("error", err.response.data);
        throw err;
      });
  },
  fetchPrecondition({ commit }) {
    let url = "https://kkera.toyota.fi/api/2.0.0/brand/1/precondition/json/";
    return Axios.get(url, {
      headers: {
        Authorization: "Token " + process.env.VUE_APP_KKERA
      }
    })
      .then(r => {
        commit("SET_PRECONDITION", r.data);
      })
      .catch(err => {
        throw err;
      });
  }
};
const mutations = {
  SET_CALCULATION: (state, payload) => {
    Vue.set(state.calculations, payload.key, payload);
  },
  SET_LOADING: (state, payload) => {
    state.loadingCalculation = payload;
  },
  SET_PRECONDITION: (state, payload) => {
    state.precondition = payload;
  }
};
export default { namespaced: true, state, getters, actions, mutations };
