import Vue from "vue";
import Axios from "axios";
import config from "../../plugins/config";

const state = {
  vehicle: {}
};
const getters = {
  getVehicle: state => state.vehicle
};
const actions = {
  fetchVehicle({ commit }, payload) {
    return Axios.get(config.baseUrl + "vehicle/" + payload)
      .then(result => {
        commit("SET_VEHICLE", result.data);
      })
      .catch(err => {
        throw err;
      });
  }
};
const mutations = {
  SET_VEHICLE: (state, payload) => {
    Vue.set(state, "vehicle", payload);
  }
};
export default { namespaced: true, state, getters, actions, mutations };
